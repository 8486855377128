/** General styles*/

.background {
  background-color: white;
}


/** Phones styles*/
@media(max-width: 576px) {
  .background {
    height: 100%;
    margin-top: 67.89px;
    background-color: transparent;
  }
  ul{
    font-size: 15px;
    color: #999999;
  }
  .rowimg{
    display: flex;
  }
  .rowimg img{
    max-height: 70%;
    width: 100%;
    height: 37vh;
    margin: auto;  
    border-radius: 18px;
    object-fit: cover;
  }
  .buttonshare{
    display: flex;
    margin-top: -5%;
    margin-bottom: 2vh;
  }
  .buttonshare img{
    width: 39%;
    /* height: 4vh; */
    margin-left: 3vw;
    margin-top: -2vh;
  }
  .rowtitle{
    font-weight: 700;
    line-height: 50%;
    margin-top: -5%;
    height: 5vh;
  }
  .texttitle{
    font-size: 13px;
    margin-bottom: 1vh;
    line-height: 2VH;
   
    width: 74vw;
    font-family: 'avenir-italic';
  }
  .textsubtitle{
    color: #999999;
    font-weight: 400;
    
  }
  .textdetailtitle{
    font-size: 9px;
    color: #999999;
    width: 23vw;

  }
  .textdetailtitlemobile{
    font-size: 9px;
    color: #999999;
    width: 43vw;
  }
  .textdetailinfo{
    font-size: 12px;
  }
  .rowdetail{
    line-height: 0px;
    margin-top: 6%;
    padding: 0;
  }
  .rowdetailimg img{
    max-width: 86%;
  }
  .rowdetailimg {
    padding: 0;
    padding-left: 3.5%;
    margin: auto;
  }
  .rowtextingredients{
    margin-top: 3vh;
  }
  .textingredients{
    font-weight: 700;
    font-family: 'avenir-italic';
    
  }
  .rowtext{
    margin-top: -1vh;
  }
  .rowtextparraf{
    text-align: justify;
    color: #999999;
    line-height: 3vh;
    
  }
  .rowbutton {
       /* display: flex; */
    /* margin-top: 10%; */
    /* height: 30vh;*/
 
  }
  .rowbutton img{
    width: 100%;
    object-fit: scale-down;
    
  }
  .iconredes{
    padding: 0;
    padding-left: 3.5%;
    margin: auto;
    margin-top: 2vh;
    margin-bottom: 2vh;
    
  }
  .iconredeswa{
    padding: 0;
    padding-left: 3.5%;
    margin: auto;
    margin-left: -24vw;
    margin-top: 2vh;
    margin-bottom: 2vh;
  
  }
  .iconredeswa img{
    max-width: 100%;
  }
  .iconredesphone{
    padding: 0;
    padding-left: 3.5%;
    margin: auto;
    margin-left: -24vw;
    margin-top: 2vh;
    margin-bottom: 2vh;
    
  }
  .iconredesphone img{
    max-width: 100%;
  }
  .iconredes img{
    max-width: 100%;
  }
  .imgicon{
    width: 10vw;
  }
  .desktop{
    display: none;
  }
  
  .iconPlatos{
    width: 100%;
    height: 100%;
    display: flex;
  }
  .iconPlatos img{
    width: 50%;
    height: 100%;
    margin: auto;

  }
  .buttonback{
    text-decoration: none !important;
    color: white !important;
  }
  .buttonback div{
    width: 50%;
    padding: 10px 0;
    border-radius: 5px;
    background-color: red;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
  }
}

/** Landscape phones and tablets styles*/
@media (min-width: 576px) and (max-width: 992px) {
  .background {
    height: 100%;
    margin-top: 70px;
    background-color: transparent;
    min-height: 100vh;
  }
  ul{
    font-size: 15px;
    color: #999999;
  }
  .shadow{
    box-shadow: 0px 20px 9px -9px rgba(0,0,0,0.08);
  }
  .row{
    padding-left: 13vw;
  }
  .rowimg{
    display: flex;
  }
  .rowimg img{
    object-fit: cover;
    width: 68vw;
    height: 41vh;
    margin: auto;  
    border-radius: 18px;
  }
  .buttonshare{
    display: flex;
    margin-top: -5%;
  }
  .buttonshare img{
    max-width: 23%;
    margin: auto;
    margin-left: 74%;
  }
  .rowtitle{
    width: 8vw;
  }
  .rowtitlemiddle{
    width: 8vw;
    margin-bottom: 5vh;
  }
  .rowtitleright{
    width: 8vw;
    margin-left: 8vw;
  }

  
  .imgiconmenu{
    width: 8vw;
  }
  .texttitle{
    font-size: 21px;
    width: 39vw;
    font-weight: bold;
    font-family: 'avenir-italic';
    color: #202c38
  }
  .textsubtitle{
    color: #999999;
    font-weight: 400;
    text-align: justify;
    margin-top: -1vh;
    line-height: 3vh;
    
  }
  .textsubtitletop{
    color: #bfbfbf;
    font-weight: 400;
    margin-top: -3vh;
  }
  .textdetailtitle{
    font-size: 9px;
    color: #999999;
    width: 23vw;

  }
  .textdetailinfo{
    font-size: 12px;
  }
  .rowdetail{
    line-height: 0px;
    margin-top: 6%;
    padding: 0;
    width: 8vw;
    margin-left: -14vw;
  }
  .rowdetailprecio{
    line-height: 0px;
    margin-top: 8%;
    padding: 0;
    width: 8vw;
    margin-left: -27vw;
  }
  .rowdetailtextprecio{
    line-height: 0px;
    margin-top: 8%;
    padding: 0;
    width: 8vw;
    margin-left: -24vw;
  }
  .rowdetailimg img{
    max-width: 25%;
  }
  .rowdetailimg {
    padding: 0;
    padding-left: 3.5%;
    margin: auto;
  }
  .iconredes{
    padding: 0;
    padding-left: 3.5%;
    margin: auto;
  }
  .iconredeswa{
    padding: 0;
    padding-left: 3.5%;
    margin: auto;
    margin-left: -24vw;
  
  }
  .iconredeswa img{
    max-width: 40%;
  }
  .iconredesphone{
    padding: 0;
    padding-left: 3.5%;
    margin: auto;
    margin-left: -24vw;
  
  }
  .iconredesphone img{
    max-width: 40%;
  }
  .iconredes img{
    max-width: 40%;
  }
  .imgicon{
    width: 10vw;
  }
  .rowtextingredients{
    margin-top: 3vh;
  }
  .textingredients{
    font-weight: 700;
  }
  .rowtext{
    margin-top: -1vh;
  }
  .rowtextparraf{
    text-align: justify;
    color: gray;
  }
  .rowbutton {
    display: flex;
  }
  .rowbutton img{
    max-width: 50%;
   
  }
  .imgmenu{
    width: 58%;
    margin: auto;
    margin-top: 10vh;
    margin-bottom: 3vh;
    margin-left: 13vw;
  }

  .iconPlatos{
    width: 100%;
    height: 100%;
    display: flex;
  }
  .iconPlatos img{
    width: 50%;
    height: 100%;
    margin: auto;

  }

  .mobile{
    display: none;
  }
  .buttonback{
    text-decoration: none !important;
    color: white !important;
    width: 25%;
    padding: 10px 0;
    border-radius: 5px;
    background-color: red;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    border: none;
  }
}

/** Landscape tablets and medium to large screens styles*/
@media (min-width: 992px) {
  .background {
    height: 100%;
    margin-top: 80px;
    background-color: transparent;
    min-height: 100vh;
  }
  ul{
    font-size: 15px;
    color: #999999;
  }
  .shadow{
    box-shadow: 0px 20px 9px -9px rgba(0,0,0,0.08);
  }
  .row{
    padding-left: 13vw;
  }
  .rowimg{
    display: flex;
  }
  .rowimg img{
    width: 45vw;
    height: 41vh;
    margin: auto;  
    border-radius: 18px;
    object-fit: cover;
  }
  .buttonshare{
    display: flex;
    margin-top: -5%;
  }
  .buttonshare img{
    max-width: 23%;
    margin: auto;
    margin-left: 74%;
  }
  .rowtitle{
    width: 8vw;
  }
  .rowtitlemiddle{
    width: 8vw;
    margin-bottom: 5vh;
  }
  .rowtitleright{
    width: 8vw;
    margin-left: 8vw;
  }

  
  .imgiconmenu{
    width: 8vw;
  }
  .texttitle{
    font-size: 21px;
    width: 39vw;
    font-weight: bold;
    font-family: 'avenir-italic';
    color: #202c38
  }
  .textsubtitle{
    color: #999999;
    font-weight: 400;
    text-align: justify;
    margin-top: -1vh;
    line-height: 3vh;
  }
  .textsubtitletop{
    color: #bfbfbf;
    font-weight: 400;
    margin-top: -3vh;
  }
  .textdetailtitle{
    font-size: 9px;
    color: #999999;
    width: 23vw;

  }
  .textdetailinfo{
    font-size: 12px;
  }
  .rowdetail{
    line-height: 0px;
    margin-top: 4%;
    padding: 0;
    width: 8vw;
    margin-left: -12vw;
  }
  .rowdetailprecio{
    line-height: 0px;
    margin-top: 5.5%;
    padding: 0;
    width: 8vw;
    margin-left: -27vw;
  }
  .rowdetailtextprecio{
    line-height: 0px;
    margin-top: 5.5%;
    padding: 0;
    width: 8vw;
    margin-left: -24vw;
  }
  .rowdetailimg img{
    max-width: 25%;
  }
  .rowdetailimg {
    padding: 0;
    padding-left: 3.5%;
    margin: auto;
  }
  .iconredes{
    padding: 0;
    padding-left: 3.5%;
    margin: auto;
    margin-top: 1vh;
  }
  .iconredeswa{
    padding: 0;
    padding-left: 3.5%;
    margin: auto;
    margin-left: -24vw;
    margin-top: 1vh;
  }
  .iconredeswa img{
    max-width: 40%;
  }
  .iconredesphone{
    padding: 0;
    padding-left: 3.5%;
    margin: auto;
    margin-left: -24vw;
    margin-top: 1vh;
  }
  .iconredesphone img{
    max-width: 40%;
  }
  .iconredes img{
    max-width: 40%;
  }
  .imgicon{
    width: 10vw;
  }
  .rowtextingredients{
    margin-top: 3vh;
  }
  .textingredients{
    font-weight: 700;
  }
  .rowtext{
    margin-top: -1vh;
  }
  .rowtextparraf{
    text-align: justify;
    color: gray;
  }
  .rowbutton {
    display: flex;
  }
  .rowbutton img{
    max-width: 50%;
   
  }
  .imgmenu{
    width: 58%;
    margin: auto;
    margin-top: 10vh;
    margin-bottom: 3vh;
    margin-left: 13vw;
  }


  .mobile{
    display: none;
  }

  .iconPlatos{
    width: 100%;
    height: 100%;
    display: flex;
  }
  .iconPlatos img{
    width: 50%;
    height: 100%;
    margin: auto;

  }
  .buttonback{
    text-decoration: none !important;
    color: white !important;
    width: 15%;
    padding: 10px 0;
    border-radius: 5px;
    background-color: red;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    border: none;
  }
}