/** General styles*/

.background {
  background-color: white;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}


/** Phones styles*/

@media(max-width: 576px) {
  .background {
    height: calc(100vh - 67.89px);
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    z-index: 0;
    margin-top: 67.89px;
  }
  .backgrounddiv{
    background-image: url("../../assets/banners/SANCHOYPAUMIXTO_MENU_RESPONSIVE.jpg");
    background-size: cover;
  }
  .backgrounddiv2{
    background-image: url("../../assets/banners/MEINMIXTA_MENU_RESPONSIVE.jpg");
    background-size: cover;
  }
   .backgrounddiv3{
    background-image: url("../../assets/banners/WANTANCA_MENU_RESPONSIVE.jpg");
    background-size: cover;
  }
  .backgrounddiv4{
    background-image: url("../../assets/banners/cookies.jpg");
    background-size: cover;
    background-position-y: 33%;
  }
  .divImg{
    
  }

  .logoHolder {
      display: flex;
  }
  .divtopimg {
      width: 100%;
     
      margin: auto;
  }
  .divtopimg img{
    width: 100%;   
  }
  
  .textdivtop{
    margin: auto;
    text-align: center;
  }
  .buttonFuertesPlatos{
    display: flex;
    color: white;
   
  }
  .buttonFuertes p{
   margin-top: 1vh;
    color: white;
    height: 100%;
    width: 100%;
  }
  
  .logoHoldertexttitleplatosright a{
    text-decoration: none !important;
    text-decoration-line: none !important;
    text-decoration-color: transparent;
   }
   .logoHoldertexttitleplatosfocusright{
    margin: auto;
    color: white;
    margin-top: -4vh;
    margin-left: 52.5vw;
      font-weight: 700;
    font-size: 10px;
    background-color: transparent;
    border-style: solid;
    border-width: 1px;
    border-radius: 15px;
    width: 30vw;
    background-color: red;
    border-color: red;
    height: 28px;
       
  }
  .logoHoldertexttitleplatosright{
    margin: auto;
    color: white;
    margin-top: -4vh;
    margin-left: 52.5vw;
    font-weight: 700;
    font-size: 10px;
    background-color: transparent;
    border-style: solid;
    border-width: 1px;
    border-radius: 15px;
    width: 30vw;
    border-color: white;
    height: 28px;
  }
 .logoHoldertexttitleplatos{
   margin: auto;
   color: white;
   margin-top: 10vh;
   font-weight: 700;
   font-size: 10px;
   background-color: transparent;
   border-color: red;
   border-style: solid;
   border-width: 1px;
   border-radius: 20px;
   width: 30vw;
   background-color: red;
   height: 28px;
   margin-left: 10vw;
 }
 .logoHoldertexttitle{
  margin: auto;
  color: white;
  margin-top: 10vh;
  font-weight: 700;
  font-size: 10px;
  background-color: transparent;
  border-color: white;
  border-style: solid;
  border-width: 1px;
  border-radius: 20px;
  width: 30vw;
  height: 28px;
  margin-left: 10vw;
}

.logoHoldertexttitlebottom{
  margin: auto;
  color: white;
  margin-top: 4vh;
  margin-right: 3vw;
  font-weight: 700;
  font-size: 10px;
  background-color: transparent;
  border-color: white;
  border-style: solid;
  border-width: 1px;
  border-radius: 15px;
  width: 30vw;
     height: 28px;
 
 }
 .logoHoldertexttitlebottomfocus{
  margin: auto;
  color: white;
  margin-top: 4vh;
  margin-right: 3vw;
  font-weight: 700;
  font-size: 10px;
  background-color: transparent;
  border-style: solid;
  border-width: 1px;
  border-radius: 15px;
  width: 30vw;
  background-color: red;
  border-color: red;
     height: 28px;
 }
 .logoHoldertexttitlebottomright{
   margin: auto;
   color: white;
   margin-top: 4vh;
   font-weight: 700;
   margin-left: 3vw;
   font-size: 10px;
   background-color: transparent;
   border-color: white;
   border-style: solid;
   border-width: 1px;
   border-radius: 15px;
   width: 30vw;
  height: 28px;
  
  }
  .logoHoldertexttitlebottomfocusright{
   margin: auto;
   color: white;
   margin-top: 4vh;
   margin-left: 3vw;
   font-weight: 700;
   font-size: 10px;
   background-color: transparent;
   border-style: solid;
   border-width: 1px;
   border-radius: 15px;
   width: 30vw;
   background-color: red;
   border-color: red;
   height: 28px;
  }

  .logoHoldertexttitleplatosDIM{
    margin: auto;
   color: white;
   margin-top: 3vh;
   margin-bottom: 2vh;
   font-weight: 700;
   font-size: 10px;
   background-color: transparent;
   border-color: white;
   border-style: solid;
   border-width: 1px;
   border-radius: 15px;
   width: 30vw;
  height: 28px;
  }
  .logoHoldertexttitleplatosDIMfocus{
    margin: auto;
    color: white;
    margin-top: 3vh;
    margin-bottom: 2vh;
    font-weight: 700;
    font-size: 10px;
    background-color: transparent;
    border-style: solid;
    border-width: 1px;
    border-radius: 15px;
    width: 30vw;
    background-color: red;
    border-color: red;
    height: 28px;
  }
  


.scrollmenu{
  overflow: auto;
  white-space: nowrap;
  padding-left: 2vw;
  margin-top: 1.5vh;
}
.scrollmenu a {
  display: inline-block;
  color: black;
  text-align: center;
  padding: 14px;
  text-decoration: none;
  margin-right: 2vw;
  font-size: 10px;
  
}
.scrollmenu::-webkit-scrollbar {
  width: 5px;
 height: 2px;
 overflow-y: visible;
 -webkit-appearance: none;
 
}

/* Track */
.scrollmenu::-webkit-scrollbar-track {
  box-shadow: inset 0 0 12px white; 
  border-radius: 10px;
}
 
/* Handle */
.scrollmenu::-webkit-scrollbar-thumb {
  background: red; 
  border-radius: 20px;
  
}

/* Handle on hover */
.scrollmenu::-webkit-scrollbar-thumb:hover {
  background: red; 

}
.icon{
  width: 4%;
  margin-top: 0vh;
  margin-right: -2vw;
}
.iconsopas{
  width: 27%;
  margin-top: 0vh;
  margin-right: -2vw;
}
.imgscroll{
  width: 110%;
    border-radius: 8px;
    /* padding-right: 8px; */
    margin-bottom: 43px;
    margin-top: 7px;
    margin-left: 2vw;
 }
.rowitem{
  position: absolute;
}
.title{
  margin-top: -5vh;
  font-size: 9px;
  margin-left: 2vw;
  font-weight: 700;
  color: black;
  white-space: pre-wrap;
}
.subtitle{
  margin-top: -2.5vh;
  font-size: 8px;
  margin-left: 2vw;
  font-weight: 200;
  color: gray;
}
.titleright{
  position: absolute;
  margin-top: -5vh;
  font-size: 7px;
  margin-left: 51vw;
  font-weight: 700;
  color: black;
}
.subtitleright{
  position: absolute;
  margin-top: -3vh;
  font-size: 8px;
  margin-left: 51vw;
  font-weight: 200;
  color: gray;
}


.titlesmall{
  position: absolute;
  margin-top: -5vh;
  font-size: 7px;
  margin-left: 3vw;
  font-weight: 700;
  color: black;
}
.titlerightsmall{
  position: absolute;
  margin-top: -5vh;
  font-size: 7px;
  margin-left: 51vw;
  font-weight: 700;
  color: black;
} 
 


 .logoHoldertexttitlescroll{
   font-weight: 700;
   margin-left: 40px;
   margin-bottom: -2px;   
 }
 .logoHoldertextsubscroll{
  margin-left: 40px;
  color: gray;
  font-size: 12px;
  font-weight: 500;
 }
 .divImgScroll{
   padding: 0;
 }

 .imgbutton{
  width: 65%;
  position: static;
   
 }
 .divbutton{
  position: sticky;
  margin-top: -46px;
  padding-left: 30%;
  z-index: 99;
 }
 .web{
   display: none;
 }
 .divfull{
  width: 100%;
 }
 .rowscroll{
   width: 100%;
   margin: 0 !important;
   height: 10vh;
 }
  .drinkTitle {
    font-size: 16px;
    font-weight: 500;
  }
  .drinkName {
    font-size: 14px;
    font-weight: 200;
  }
  .drinkPrice {
    font-size: 13px;
    font-weight: 200;
  }
  .drinkPriceSmall {
    font-size: 11px;
    font-weight: 200;
  }
  .drinkSubTitle{
    font-size: 18px;
    font-weight: 500;
  }
  .divBlank{
    height: 8vh;
  }
 
}



/** Landscape phones and tablets styles*/

@media (min-width: 576px) and (max-width: 992px) {
  .background {
    height: 100vh;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    z-index: 0;
   
    
}
.backgrounddiv{
  background-image: url("../../assets/banners/SANCHOYPAUMIXTO_MENU_DESKTOP.jpg");
  background-size: cover;
}
.backgrounddiv2{
  background-image: url("../../assets/banners/MEINMIXTA_MENU_DESKTOP.jpg");
  background-size: cover;
}
 .backgrounddiv3{
  background-image: url("../../assets/banners/WANTANCA_MENU_DESKTOP.jpg");
  background-size: cover;
}
.backgrounddiv4{
  background-image: url("../../assets/banners/cookies.jpg");
  background-size: cover;
  background-position-y: 33%;
}
.divImg{

}

.logoHolder {
    display: flex;
}
.divtopimg {
    width: 100%;
   
    margin: auto;
}
.divtopimg img{
  width: 100%;   
}

.textdivtop{
  margin: auto;
  text-align: center;
}
.buttonFuertes p{
  margin-top: 1vh;
   color: white;
   height: 100%;
   width: 100%;
 }
 
 .texto{
  margin-top: 0.5vh;
  width: 20vw;
 }

 .logoHoldertexttitleplatosright a{
  text-decoration: none !important;
  text-decoration-line: none !important;
  text-decoration-color: transparent;
 }
 .logoHoldertexttitleplatosfocusright{
  margin: auto;
  color: white;
  margin-top: -4vh;
  margin-left: 52.5vw;
    font-weight: 700;
  font-size: 10px;
  background-color: transparent;
  border-style: solid;
  border-width: 1px;
  border-radius: 15px;
  width: 17vw;
  background-color: red;
  border-color: red;
  height: 39px;
     
}
.logoHoldertexttitleplatosright{
  margin: auto;
  color: white;
  margin-top: -4vh;
  margin-left: 52.5vw;
    font-weight: 700;
  font-size: 10px;
  background-color: transparent;
  border-style: solid;
  border-width: 1px;
  border-radius: 15px;
  width: 17vw;
  border-color: white;
  height: 39px;
}
.logoHoldertexttitleplatos{
  margin: auto;
  color: white;
  margin-top: 11vh;
  font-weight: 700;
  font-size: 10px;
  background-color: transparent;
  margin-left: 26vw;
  border-style: solid;
  border-width: 1px;
  border-radius: 15px;
  width: 17vw;
  border-color: red;
  background-color: red;
  height: 39px;
}
.logoHoldertexttitle{
 margin: auto;
 color: white;
 margin-top: 11vh;
 font-weight: 700;
 font-size: 10px;
 margin-left: 26vw;
 background-color: transparent;
 border-color: white;
 border-style: solid;
 border-width: 1px;
 border-radius: 15px;
 width: 17vw;
 height: 39px;
 
}

.logoHoldertexttitlebottom{
  margin: auto;
  color: white;
  margin-top: 4vh;
  margin-right: 3vw;
  font-weight: 700;
  font-size: 10px;
  background-color: transparent;
  border-color: white;
  border-style: solid;
  border-width: 1px;
  border-radius: 15px;
  width: 17vw;
     height: 39px;
 
 }
 .logoHoldertexttitlebottomfocus{
  margin: auto;
  color: white;
  margin-top: 4vh;
  margin-right: 3vw;
  font-weight: 700;
  font-size: 10px;
  background-color: transparent;
  margin-top: 2vh;
  border-style: solid;
  border-width: 1px;
  border-radius: 15px;
  width: 17vw;
  border-color: red;
  background-color: red;
     height: 39px;
 }
 .logoHoldertexttitleplatosDIMfocus{
  margin: auto;
  margin-bottom: 5vh;
  margin-top: 2vh;
  color: white;
  font-weight: 700;
  font-size: 10px;
  background-color: transparent;
  border-color: red;
  border-style: solid;
  border-width: 1px;
  border-radius: 15px;
  width: 17vw;
  background-color: red;
  height: 39px;
 }
 .logoHoldertexttitleplatosDIM{
  margin: auto;
  margin-bottom: 5vh;
  margin-top: 2vh;
  color: white;
  font-weight: 700;
  font-size: 10px;
  background-color: transparent;
  border-color: white;
  border-style: solid;
  border-width: 1px;
  border-radius: 15px;
  width: 17vw;
     height: 39px;
 
 }
 .logoHoldertexttitlebottomright{
   margin: auto;
   color: white;
   margin-top: 4vh;
   font-weight: 700;
   margin-left: 3vw;
   font-size: 10px;
   background-color: transparent;
   border-color: white;
   border-style: solid;
   border-width: 1px;
   border-radius: 15px;
   width: 17vw;
      height: 39px;
  
  }
  .logoHoldertexttitlebottomfocusright{
   margin: auto;
   color: white;
   margin-top: 4vh;
   margin-left: 3vw;
   font-weight: 700;
   font-size: 10px;
   background-color: transparent;
   border-color: red;
   border-style: solid;
   border-width: 1px;
   border-radius: 15px;
   width: 17vw;
   background-color: red;
   height: 39px;
  }
  


.scrollmenu{
  overflow: auto;
  white-space: nowrap;
  border-radius: 16px;
  margin-top: -2vh;
  background-color: white;
  justify-content: center;
  text-align: center;
  height: 5vh;
  width: 82vw;
  margin-left: 9vw;
  padding-left: 2vw;
}
.scrollmenu a {
display: inline-block;
color: black;
text-align: center;
padding: 14px;
text-decoration: none;
font-weight: 700;
font-size: 10px;
margin-right: 4vw;
}
.scrollmenu::-webkit-scrollbar {
width: 5px;
height: 2px;

}

/* Track */
.scrollmenu::-webkit-scrollbar-track {
box-shadow: inset 0 0 20px white;
width: 20vw; 
border-radius: 10px;
}

/* Handle */
.scrollmenu::-webkit-scrollbar-thumb {
background: red; 
width: 2vw;

}

/* Handle on hover */
.scrollmenu::-webkit-scrollbar-thumb:hover {
background: red; 
width: 2vw;

}
.icon{
width: 1.5%;
margin-right: -1vw;
}
.iconsopas{
  width: 12%;
  margin-top: 0vh;
  margin-right: 0vw;
}
.imgscroll{
width: 110%;
  border-radius: 7px;
  /* padding-right: 8px; */
  margin-bottom: 43px;
  margin-top: 7px;
  margin-left: 2vw;
}
.rowitem{
position: absolute;
}
.divplatos{
  width: 90%;
}
.divplatos a{
  text-decoration: none;
}
.title{
margin-top: -4.5vh;
font-size: 9px;
margin-left: 2vw;
font-weight: 700;
color: black;
white-space: pre-wrap;
}
.subtitle{
margin-top: -2vh;
font-size: 8px;
margin-left: 2vw;
font-weight: 200;
color: gray;
}
.titleright{
position: absolute;
margin-top: -5vh;
font-size: 7px;
margin-left: 51vw;
font-weight: 700;
color: black;
}
.subtitleright{
position: absolute;
margin-top: -3vh;
font-size: 8px;
margin-left: 51vw;
font-weight: 200;
color: gray;
}


.titlesmall{
position: absolute;
margin-top: -5vh;
font-size: 7px;
margin-left: 3vw;
font-weight: 700;
color: black;
}
.titlerightsmall{
position: absolute;
margin-top: -5vh;
font-size: 7px;
margin-left: 51vw;
font-weight: 700;
color: black;
} 



.logoHoldertexttitlescroll{
 font-weight: 700;
 margin-left: 40px;
 margin-bottom: -2px;   
}
.logoHoldertextsubscroll{
margin-left: 40px;
color: gray;
font-size: 12px;
font-weight: 500;
}
.divImgScroll{
 padding: 0;
}

.imgbutton{
width: 65%;
position: static;
 
}
.divbutton{
position: sticky;
margin-top: -46px;
padding-left: 30%;
z-index: 99;
}
.web{
 display: none;
}
.divfull{
  padding-left: 19vw;
  padding-right: 20vw;
  margin-top: 3vh;
}
.rowscroll{
 width: 100%;
 margin: 0 !important;
}
.drinkTitle {
  font-size: 20px;
  font-weight: 500;
}
.drinkSubTitle{
  font-size: 18px;
  font-weight: 500;
}
.drinkName {
  font-size: 14px;
  font-weight: 200;
}
.drinkPrice {
  font-size: 13px;
  font-weight: 200;
}
.drinkPriceSmall{
  font-size: 13px;
  font-weight: 200;
}
.divBlank{
  height: 8vh;
}
}


/** Landscape tablets and medium to large screens styles*/

@media (min-width: 992px) {
  .background {
    height: 100vh;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    z-index: 0;
    
    
}
.backgrounddiv{
  background-image: url("../../assets/banners/SANCHOYPAUMIXTO_MENU_DESKTOP.jpg");
  background-size: cover;
}
.backgrounddiv2{
  background-image: url("../../assets/banners/MEINMIXTA_MENU_DESKTOP.jpg");
  background-size: cover;
}
 .backgrounddiv3{
  background-image: url("../../assets/banners/WANTANCA_MENU_DESKTOP.jpg");
  background-size: cover;
}
.backgrounddiv4{
  background-image: url("../../assets/banners/cookies.jpg");
  background-size: cover;
  background-position-y: 33%;
}
.divImg{
  height: 49vh;
}

.logoHolder {
    display: flex;
}
.divtopimg {
    width: 100%;
   
    margin: auto;
}
.divtopimg img{
  width: 100%;   
}

.textdivtop{
  margin: auto;
  text-align: center;
}
.buttonFuertes p{
  margin-top: 1.5vh;
   color: white;
   height: 100%;
   width: 100%;
 }
 .buttonFuertes {
  text-decoration: none !important;
  margin-top: 1.5vh;
 }
 
 .logoHoldertexttitleplatosright a{
  text-decoration: none !important;
  text-decoration-line: none !important;
  text-decoration-color: transparent;
 }

 .logoHoldertexttitleplatos a{
  text-decoration: none !important;
  text-decoration-line: none !important;
  text-decoration-color: transparent;
 }
 .logoHoldertexttitleplatosfocusright{
  margin: auto;
  color: white;
  margin-top: -5vh;
  margin-left: 52vw;
    font-weight: 700;
  font-size: 10px;
  background-color: transparent;
  border-style: solid;
  border-width: 1px;
  border-radius: 15px;
  width: 17vw;
  background-color: red;
  border-color: red;
  height: 39px;
     
}
.logoHoldertexttitleplatosright{
  margin: auto;
  color: white;
  margin-top: -5vh;
  margin-left: 52vw;
    font-weight: 700;
  font-size: 10px;
  background-color: transparent;
  border-style: solid;
  border-width: 1px;
  border-radius: 15px;
  width: 17vw;
  border-color: white;
  height: 39px;
}
 .logoHoldertexttitlebottom a{
  text-decoration: none;
 }
.logoHoldertexttitleplatos{
  margin: auto;
  color: white;
  margin-top: 18vh;
  font-weight: 700;
  font-size: 10px;
  background-color: transparent;
  border-style: solid;
  border-width: 1px;
  border-radius: 15px;
  width: 17vw;
  background-color: red;
  border-color: red;
  height: 39px;
  margin-left: 27.5vw;
     
}
.logoHoldertexttitleplatosDIMfocus{
  margin: auto;
  color: white;
  margin-top: 3vh;
  font-weight: 700;
  font-size: 10px;
  background-color: transparent;
  border-style: solid;
  border-width: 1px;
  border-radius: 15px;
  width: 17vw;
  background-color: red;
  border-color: red;
  height: 39px;
}
.logoHoldertexttitleplatosDIM{
  margin: auto;
  color: white;
  margin-top: 4vh;
  font-weight: 700;
  font-size: 10px;
  background-color: transparent;
  border-color: white;
  border-style: solid;
  border-width: 1px;
  border-radius: 15px;
  width: 17vw;
     height: 39px;
 
 }
.logoHoldertexttitle{
 margin: auto;
 color: white;
 margin-top: 18vh;
 font-weight: 700;
 font-size: 10px;
 background-color: transparent;
 border-color: white;
 border-style: solid;
 border-width: 1px;
 border-radius: 15px;
 width: 17vw;
 height: 39px;
 margin-left: 27.5vw;

 
}

.logoHoldertexttitlebottom{
 margin: auto;
 color: white;
 margin-top: 4vh;
 margin-left: 27.5vw;
 font-weight: 700;
 font-size: 10px;
 background-color: transparent;
 border-color: white;
 border-style: solid;
 border-width: 1px;
 border-radius: 15px;
 width: 17vw;
    height: 39px;

}
.logoHoldertexttitlebottomfocus{
 margin: auto;
 color: white;
 margin-top: 4vh;
 margin-left: 27.5vw;
 font-weight: 700;
 font-size: 10px;
 background-color: transparent;
 border-style: solid;
 border-width: 1px;
 border-radius: 15px;
 width: 17vw;
 background-color: red;
 border-color: red;
    height: 39px;
}
.logoHoldertexttitlebottomright{
  margin: auto;
  color: white;
  margin-top: 4vh;
  font-weight: 700;
  margin-left: 2.5vw;
  font-size: 10px;
  background-color: transparent;
  border-color: white;
  border-style: solid;
  border-width: 1px;
  border-radius: 15px;
  width: 17vw;
     height: 39px;
 
 }
 .logoHoldertexttitlebottomfocusright{
  margin: auto;
  color: white;
  margin-top: 4vh;
  margin-left: 2.5vw;
  font-weight: 700;
  font-size: 10px;
  background-color: transparent;
  border-style: solid;
  border-width: 1px;
  border-radius: 15px;
  width: 17vw;
  background-color: red;
  border-color: red;
  height: 39px;
 }
 




.scrollmenu{
overflow: auto;
white-space: nowrap;
border-radius: 16px;
margin-top: -4vh;
background-color: white;
justify-content: center;
text-align: center;
height: 10vh;
margin-left: 4vw;
padding-left: 0vw;
width: 90vw;


}
.scrollmenu a {
display: inline-block;
color: black;
text-align: center;
padding: 14px;
text-decoration: none;
font-weight: 700;
}
.scrollmenu::-webkit-scrollbar {
width: 5px;
height: 5px;

}

/* Track */
.scrollmenu::-webkit-scrollbar-track {
box-shadow: inset 0 0 5px white; 
border-radius: 10px;
}

/* Handle */
.scrollmenu::-webkit-scrollbar-thumb {
background: red; 

}

/* Handle on hover */
.scrollmenu::-webkit-scrollbar-thumb:hover {
background: red; 

}
.icon{
width: 1.5%;
margin-right: -0.5vw;
margin-left: 1vw;
}
.iconsopas{
  width: 12%;
  margin-top: 0vh;
  margin-right: 0vw;
}
.imgscroll{
width: 110%;
  border-radius: 7px;
  /* padding-right: 8px; */
  margin-bottom: 43px;
  margin-top: 7px;
  margin-left: 2vw;
}
.rowitem{
position: absolute;
}
.divplatos{
  width: 90%;
}
.divplatos a{
  text-decoration: none;
}
.title{
margin-top: -4.5vh;
font-size: 13px;
margin-left: 2vw;
font-weight: 700;
color: black;
white-space: pre-wrap;
}
.subtitle{
margin-top: -2vh;
font-size: 10px;
margin-left: 2vw;
font-weight: 200;
color: gray;
}
.titleright{
position: absolute;
margin-top: -5vh;
font-size: 7px;
margin-left: 51vw;
font-weight: 700;
color: black;
}
.subtitleright{
position: absolute;
margin-top: -3vh;
font-size: 8px;
margin-left: 51vw;
font-weight: 200;
color: gray;
}


.titlesmall{
position: absolute;
margin-top: -5vh;
font-size: 7px;
margin-left: 3vw;
font-weight: 700;
color: black;
}
.titlerightsmall{
position: absolute;
margin-top: -5vh;
font-size: 7px;
margin-left: 51vw;
font-weight: 700;
color: black;
} 



.logoHoldertexttitlescroll{
 font-weight: 700;
 margin-left: 40px;
 margin-bottom: -2px;   
}
.logoHoldertextsubscroll{
margin-left: 40px;
color: gray;
font-size: 12px;
font-weight: 500;
}
.divImgScroll{
 padding: 0;
}

.imgbutton{
width: 65%;
position: static;
 
}
.divbutton{
position: sticky;
margin-top: -46px;
padding-left: 30%;
z-index: 99;
}
.web{
 display: none;
}
.divfull{
  padding-left: 18vw;
  padding-right: 18vw;
  margin-top: 2vh;
  width: 100%;
}
.rowscroll{
 width: 100%;
 margin: 0 !important;
}
  .drinkTitle {
    font-size: 24px;
    font-weight: 500;
  }
  .drinkSubTitle{
    font-size: 18px;
    font-weight: 500;
  }
  .drinkName {
    font-size: 14px;
    font-weight: 200;
  }
  .drinkPrice {
    font-size: 13px;
    font-weight: 200;
  }
  .drinkPriceSmall{
    font-size: 13px;
    font-weight: 200;
  }
  .divBlank{
    height: 8vh;
  }
}