/** General styles*/

.background {
background-color: transparent;
}


/** Phones styles*/

@media(max-width: 576px) {
  .background {
    height: 90vh;
    margin-top: 67.89px;
  }
  .button{
    position: absolute;
    width: 60%;
    bottom: 6vh;
    left: 20vw;
  }
  .divDireccion{
    display: none;
  }
  .divMap{
    height: 100%;
    margin-left: -15px;
    margin-right: -15px;
  }
}



/** Landscape phones and tablets styles*/

@media (min-width: 576px) and (max-width: 992px) {
  .background {
    height: 92vh;
    margin-top: 8vh;
}
.divMap{
  left: -4vw;
  top: -1vh;
}
.img{
  width: 80%;
  margin-top: 1vh;
}
.row{
  margin-top: 2vh;
  
}
.divDireccion{
  margin-top: -2vh;
  height: 91vh;
  overflow-y: scroll;
}
.img img{
  width: 100%;
}
.texto{
  margin-top: 2vh;
  font-size: 12px;
  margin-left: -1vw;
}
.horario{
  margin-top: 2vh;
  font-size: 13px;
  font-weight: 600;
}
.button{
  display: none;
}

}


/** Landscape tablets and medium to large screens styles*/

@media (min-width: 992px) {
  .background {
    height: 100vh;
}
.img{
  width: 80%;
  margin-top: 1vh;
}
.row{
  margin-top: 2vh;
  
}
.divDireccion{
  margin-top: 8vh;
  height: 90vh;
  overflow-y: scroll;
}
.img img{
  width: 100%;
}
.texto{
  margin-top: 2vh;
  font-size: 12px;
  margin-left: -1vw;
}
.horario{
  margin-top: 2vh;
  font-size: 13px;
  font-weight: 600;
}
.button{
  display: none;
}
.divMap{
  left: -4vw;
  top: 0vh;
}
}