/** General styles*/
.socialContainer {
  position: fixed;
  right: 3vw;
  bottom: 5vh;
  z-index: 99;
}

/** Phones styles*/
@media(max-width: 576px) {
  .socialContainer {
    position: fixed;
    right: 6vw;
    bottom: 5vh;
  }

  .imgicon {
    margin: 10px 0;
    max-width: 5vw;
    max-height: 5vh;
  }

  .imgicon a img {
    width: 37px;
    height: 37px;
  }
}

/** Landscape phones and tablets styles*/
@media (min-width: 576px) and (max-width: 992px) {
  .imgicon {
    margin: 10px 0;
    max-width: 5vw;
    max-height: 5vh;
  }

  .imgicon a img {
    width: 45px;
    height: 45px;
  }
}

/** Landscape tablets and medium to large screens styles*/
@media (min-width: 992px) {
  .imgicon {
    margin: 10px 0;
    max-width: 5vw;
    max-height: 5vh;
  }

  .imgicon a img {
    width: 50px;
    height: 50px;
  }
}