/** General styles*/

.background {
  background-image: url("../../assets/MenuIMG//Platos_Fuertes/ARROZ/ARROZM_MENU-normal.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    overflow: hidden;
}
/** Phones styles*/

@media(max-width: 576px) {
  .background {
    height: calc(100vh - 67.89px);
    margin-top: 67.89px;
    overflow: auto;
  }
  .layout{
    background-color: rgba(0, 0, 0, 0.7);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .aboutus{
    background-color: gray;
  }
  .descubre{
   font-size: 11px;
  }
  .row{
   
    height: 10vh;
  }
  .divtitletop{
    padding-left: 9%;
    padding-right: 9%;
    color: white;
    margin-top: 3vh;
  }
  .divtitletop hr{
    background-color: white;
    height: 1px;
  }
 
  .divtitle{
    padding-left: 9%;
    padding-right: 9%;
    color: white;
  }
  .textabout{
    text-align: justify;
    line-height: 2.5vh;
    color: white;
    font-family: 'avenir-normal';
    font-size: 11px;
  }
  .titleabout{
    font-weight: bold;
    color: white;
    font-family: 'arial-bold';
    font-size: 26px;
    margin-top: -1.5vh;
  }
  .textbottom{
    font-size: 10px;
    color: white;
    font-family: 'avenir-italic';
    margin-top: 0.5vh;
    text-align: center;
  }
  .numberbottom{
    color: white;
    font-size: 26px;
    font-family: 'arial-bold';
    text-align: center;
    
  }
.divbottomtext{
  padding-left: 0;
}
  .title{
    margin-bottom: 0%;
    color: white;
    margin-top: 3vh;
    font-family: 'arial-bold';
  }
  .subtitle{
    color: red;
    font-size: 10px;
    font-family: 'arial-bold';
  }
  .centerbottom{
    border-right-style: solid;
    border-left-style: solid;
    border-left-width: 0.5px;
    border-right-width: 0.5px;
    border-color: white;
  }
  .rowbottom{
    padding-right: 10vw;
  }
}


/** Landscape phones and tablets styles*/

@media (min-width: 576px) and (max-width: 992px) {
  .background {
    height: 100vh;
  }
  .background {
    background-image: url("../../assets/MenuIMG/Platos_Fuertes/ARROZ/ARROZM_MENU-normal.png");
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      
  }
  .layout{
    background-color: rgba(0, 0, 0, 0.7);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .aboutus{
    background-color: gray;
  }
  .descubre{
   font-size: 11px;
  }
  .row{
    
    height: 4.5vh;
  }
  .divtitletop{
    padding-left: 9%;
    padding-right: 9%;
    color: white;
    margin-top: 3vh;
  }
  .divtitletop hr{
    background-color: white;
    height: 1px;
    width: 20vw;
    margin-left: 0vw;
  }
 
  .divtitle{
    padding-left: 9%;
    padding-right: 9%;
    color: white;
  }
  .textabout{
    text-align: justify;
    line-height: 2.5vh;
    color: white;
    font-family: 'avenir-normal';
    font-size: 11px;
  }
  .titleabout{
    font-weight: bold;
    color: white;
    font-family: 'arial-bold';
    font-size: 26px;
    margin-top: -1.5vh;
  }
  .textbottom{
    font-size: 10px;
    color: white;
    font-family: 'avenir-italic';
    margin-top: 0.5vh;
  }
  .numberbottom{
    color: white;
    font-size: 26px;
    font-family: 'arial-bold';
    
  }
.divbottomtext{
  padding-left: 0;
}
  .title{
    margin-bottom: 0%;
    color: white;
    margin-top: 3vh;
    font-family: 'arial-bold';
  }
  .subtitle{
    color: red;
    font-size: 10px;
    font-family: 'arial-bold';
  }
  .centerbottom{
    border-right-style: solid;
    border-left-style: solid;
    border-left-width: 0.5px;
    border-right-width: 0.5px;
    border-color: white;
  }
  .rowbottom{
    padding-right: 10vw;
  }

}


/** Landscape tablets and medium to large screens styles*/

@media (min-width: 992px) {
  .background {
    height: 100vh;
  }
  .background {
    background-image: url("../../assets/MenuIMG/Platos_Fuertes/ARROZ/ARROZM_MENU-normal.png");
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      
  }
  .layout{
    background-color: rgba(0, 0, 0, 0.7);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .aboutus{
    background-color: gray;
  }
  .descubre{
   font-size: 15px;
  }
  .row{
    
    height: 4.5vh;
  }
  .divtitletop{
    padding-left: 9%;
    padding-right: 9%;
    color: white;
    margin-top: 3vh;
  }
  .divtitletop hr{
    background-color: white;
    height: 1px;
    width: 20vw;
    margin-left: 0vw;
  }
 
  .divtitle{
    padding-left: 9%;
    padding-right: 9%;
    color: white;
  }
  .textabout{
    text-align: justify;
    line-height: 3vh;
    color: white;
    font-family: 'avenir-normal';
    font-size: 13px;
  }
  .titleabout{
    font-weight: bold;
    color: white;
    font-family: 'arial-bold';
    font-size: 49px;
    margin-top: -1.5vh;
  }
  .textbottom{
    font-size: 10px;
    color: white;
    font-family: 'avenir-italic';
    padding-top: 1.5vh;
  }
  .numberbottom{
    color: white;
    font-size: 26px;
    font-family: 'arial-bold';
   
  }
.divbottomtext{
  padding-left: 0;
}
  .title{
    margin-bottom: 0%;
    color: white;
    margin-top: 3vh;
    font-family: 'arial-bold';
    font-size: 19px;
  }
  .subtitle{
    color: red;
    font-size: 16px;
    font-family: 'arial-bold';
  }
  .centerbottom{
    border-right-style: solid;
    border-left-style: solid;
    border-left-width: 0.5px;
    border-right-width: 0.5px;
    border-color: white;
  }
  .divcontainer{
    padding-left: 17vw;
    padding-right: 18vw;
  }
  .rowbottom{
    padding-right: 10vw;
  }
}