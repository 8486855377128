/** General styles*/

.background {
  background-color: #222222;
}


/** Phones styles*/

@media(max-width: 576px) {
  .background {
    height: 460px;
  }
 .imagelogo{
    height: 3.5vh;
    margin-top: 28%;
    margin-left: 13%;
 }
 .firsttext{
    text-align: justify;
    padding-left: 13%;
    padding-right: 13%;
    font-size: 10px;
    padding-top: 10%;
    color: #858585;
 }
 .copyright{
    display: flex;
    padding-left: 13%;
    padding-right: 13%;
    font-size: 8px;
    color: #858585;
 }
 .colortextcopyright{
    color: red;
 }
 .textreservation{
    color: #ededed;
    font-size: 15px;
    font-weight: 700;
    padding-left: 13%;
    padding-right: 13%;
 }
 .schedule{
  color: #858585;
  font-size: 10px;
  padding-left: 13%;
  padding-right: 13%;
  margin-bottom: 2vh;
 }
 .schedule2{
   color: #858585;
   font-size: 10px;
   padding-left: 13%;
   padding-right: 13%;
 }
 .buttonCall{
   display: flex;
 }

 .desktop{
   display: none;
 }
 .imgicon img{
   width: 100%;
}
.imgCall{
   height: 100%;
   width: 100%;
   padding-top: 0%;
}
.buttonHolder{
  
}
}



/** Landscape phones and tablets styles*/

@media (min-width: 576px) and (max-width: 992px) {
  .background {
    height: 45vh;
  }
 .imagelogo{
    max-width: 33%;
    margin-top: 8%;
    margin-left: 13%;
 }
 .firsttext{
    text-align: justify;
    padding-left: 13%;
    padding-right: 13%;
    font-size: 12px;
    padding-top: 10%;
    color: #858585;
 }
 .copyright{
    display: flex;
    padding-left: 13%;
    padding-right: 13%;
    font-size: 8px;
    color: #858585;
 }
 .colortextcopyright{
    color: red;
 }
 .textreservation{
    color: #ededed;
    font-size: 15px;
    font-weight: 700;
    padding-left: 22%;
    padding-right: 13%;
    margin-top: 23%;
 }
 .schedule{
  color: #858585;
  font-size: 10px;
  padding-left: 22%;
  padding-right: 13%;
  margin-top: -2%;
  margin-bottom: 2vh;
 }
 .schedule2{
   color: #858585;
   font-size: 10px;
   padding-left: 22%;
   padding-right: 13%;
   margin-top: -2%;
 }
 .buttonCall{
   display: flex;
   justify-content: flex-end;
 }
 .imgicon img{
   width: 100%;
}
.imgCall{
   height: 100%;
   width: 100%;
   padding-top: 0%;
   
}
 .mobile{
   display: none;
 }
 
}


/** Landscape tablets and medium to large screens styles*/

@media (min-width: 992px) {
  .background {
    height: 47vh;
  }
 .imagelogo{
    max-width: 26%;
    margin-top: 16%;
    margin-left: 13%;
 }
 .imgicon img{
    width: 100%;
 }
 .firsttext{
    text-align: justify;
    padding-left: 13%;
    padding-right: 13%;
    font-size: 12px;
    padding-top: 5%;
    color: #858585;
 }
 .copyright{
    display: flex;
    padding-left: 13%;
    padding-right: 13%;
    font-size: 8px;
    color: #858585;
 }
 .colortextcopyright{
    color: red;
 }
 .textreservation{
    color: #ededed;
    font-size: 15px;
    font-weight: 700;
    padding-left: 40%;
    padding-right: 13%;
    margin-top: 24%;
 }
 .schedule{
  color: #858585;
  font-size: 10px;
  padding-left: 40%;
  padding-right: 13%;
  margin-top: 0%;
  margin-bottom: 2vh;
 }
 .schedule2{
   color: #858585;
   font-size: 10px;
   padding-left: 40%;
   padding-right: 13%;
   margin-top: 0%;
 }
 .buttonCall{
  
 }
 .imgCall{
   height: 100%;
    width: 100%;
    padding-top: 0%;
    
 }
 .mobile{
   display: none;
 }
 
}