/** General styles*/

.background {
  background-color: white;
}


/** Phones styles*/

@media(max-width: 576px) {
  .background {
    height: 285px;
    margin-top: -94px;
    background-color: transparent;
  }
  .title {
    font-weight: bold;
    color: white;
    margin-bottom: -26px;
    margin-left: -1px;
    margin-top: 10px;
  }
  .itemimg {
    max-width: 95%;
    position: relative;
    border-radius: 15px;
    margin: auto;
    border: none;
  }
  .itemimgGRANDE{
    max-width: 95%;
    position: relative;
    border-radius: 15px;
    margin: auto;
    border: none;
    height: 23.5vh;
  }
  .containercarousel{
      padding-left: 0;
      padding-right: 0;

  }
  .carContainer{
    width: 100%;
    margin-top: 31px;
    
  }
  .carContainer button{
    width: 104%;
  }
  .areaimgcenter {
    height: 56vh;
    margin-top: 38px;
    background-image: url('../../assets/home/a_domicilio_responsive.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
  .imgcenter {
    margin: auto;
    width: 95%;
    border-width: 1px;
    border-color: red;
    border-style: solid;
  
  }
  .textitem {
    position: absolute;
    top: 74%;
    left: 5%;
    font-weight: bold;
    color: white;
    font-size: 16px;
  }
  .textitembottom {
    font-size: 9px;
    position: absolute;
    top: 85%;
    left: 5%;
    color: white;
  }
  .iconitem {
    width: 10%;
    position: absolute;
    top: 60%;
    left: 5%;
    color: white;
    filter: white;
  
  }
  .button {
    border: none;
    background-color: transparent;
    padding: 0;
  }
  .carDiv{
    margin-left: 17px;
  }
  .carDivWeb{
    display: none;
  }
}


/** Landscape phones and tablets styles*/

@media (min-width: 576px) and (max-width: 992px) {
  .background {
     height: 26vh;;
    margin-top: 60px;
    background-color: transparent;
  }
  .title {
    font-weight: bold;
    color: black;
    padding-left: 8.5%;
    font-size: 20px;
    margin-bottom: -15px;
  }
  .itemimg {
    max-width: 95%;
    position: relative;
    border-radius: 15px;
    margin: auto;
    border: none;
  }
  .containercarousel{
      padding-left: 0;
      padding-right: 0;
  }
  .carContainer{
    width: 97%;
    
  }
  .carContainer button{
    width: 104%;
  }
  .areaimgcenter {
    height: 56vh;
    margin-top: 38px;
    background-image: url('../../assets/home/a_domicilio.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
  .Divareaimgcenter{
    display: flex;
    padding-top: 10px;
    padding-left: 0;
    padding-right: 0;
  }
  .Divareaimgcenterleft{
    display: flex;
    padding-top: 10px;
    padding-left: 10%;
    padding-right: 0;
  }
  .Divareaimgcenterright{
    display: flex;
    padding-top: 10px;
    padding-right: 0;
    padding-left: 0;
  }
  .Divimgcenter{
    margin: auto;
    width: 95%;
    height: 83%;
    border-radius: 16px;
    object-fit: cover;
  }
  .imgcenter {
    margin: auto;
    width: 95%;
    border-width: 1px;
    border-color: red;
    border-style: solid;
  }
  .textitem {
    position: absolute;
    top: 58%;
    left: 8%;
    font-weight: bold;
    color: white;
    font-size: 13px;
  }
  .textitembottom {
    font-size: 10px;
    position: absolute;
    top: 68%;
    left: 8%;
    color: white;
  }
  .iconitem {
    width: 10%;
    position: absolute;
    top: 42%;
    left: 8%;
    color: white;
  }
  .textitemright {
    position: absolute;
    top: 58%;
    left: 32%;
    font-weight: bold;
    color: white;
    font-size: 13px;
  }
  .textitembottomright {
    font-size: 10px;
    position: absolute;
    top: 68%;
    left: 32%;
    color: white;
  }
  .iconitemright {
    width: 10%;
    position: absolute;
    top: 38%;
    left: 32%;
    color: white;
  }
  .button {
    border: none;
    background-color: transparent;
    padding: 0;
  }
  .carDiv{
    display: none;
  }
  .rowimg{
    width: 100%; 
  }
}


/** Landscape tablets and medium to large screens styles*/

@media (min-width: 992px) {
  .background {
    height: 26vh;;
   margin-top: 60px;
   background-color: transparent;
 }
 .title {
   font-weight: bold;
   color: black;
   padding-left: 9.5%;
   font-size: 20px;
   margin-bottom: 0px;
 }
 .itemimg {
   max-width: 95%;
   position: relative;
   border-radius: 15px;
   margin: auto;
   border: none;
 }
 .containercarousel{
     padding-left: 0;
     padding-right: 0;
 }
 .carContainer{
   width: 97%;
   
 }
 .carContainer button{
   width: 104%;
 }
 .areaimgcenter {
   height: 56vh;
   margin-top: 38px;
   background-image: url('../../assets/home/a_domicilio.jpg');
   background-repeat: no-repeat;
   background-position: center;
   background-size: cover;
 }
 .Divareaimgcenter{
   display: flex;
   padding-top: 10px;
   padding-left: 0;
   padding-right: 0;
 }
 .Divareaimgcenterleft{
   display: flex;
   padding-top: 10px;
   padding-left: 10%;
   padding-right: 0;
 }
 .Divareaimgcenterright{
   display: flex;
   padding-top: 10px;
   padding-right: 0%;
   padding-left: 0;
 }
 .Divimgcenter{
   margin: auto;
   width: 95%;
   height: 83%;
   border-radius: 16px;
   object-fit: cover;
 }
 .imgcenter {
   margin: auto;
   width: 95%;
   border-width: 1px;
   border-color: red;
   border-style: solid;
 }
 .textitem {
   position: absolute;
   top: 61%;
   left: 8%;
   font-weight: bold;
   color: white;
   font-size: 13px;
 }
 .textitembottom {
   font-size: 10px;
   position: absolute;
   top: 68%;
   left: 8%;
   color: white;
 }
 .iconitem {
   width: 9%;
   position: absolute;
   top: 42%;
   left: 8%;
   color: white;
 }
 .textitemright {
   position: absolute;
   top: 61%;
   left: 39%;
   font-weight: bold;
   color: white;
   font-size: 13px;
 }
 .textitembottomright {
   font-size: 10px;
   position: absolute;
   top: 68%;
   left: 39%;
   color: white;
 }
 .iconitemright {
  width: 6%;
  position: absolute;
  top: 41%;
  left: 39%;
  color: white;
 }
 .button {
   border: none;
   background-color: transparent;
   padding: 0;
 }
 .carDiv{
   display: none;
 }
 .rowimg{
   width: 100%; 
 }
}