/** General styles*/
.imgContainer {
  width: 100%;
  height: 100%;
}

.imgContainer img {
  max-width: 100%;
  border-radius: 15px;
}
.item{

}
/** Phones styles*/
@media(max-width: 576px) {

}

/** Landscape phones and tablets styles*/
@media (min-width: 576px) and (max-width: 992px) {
  .textContainer{
    width: 42vw;
    text-align: justify;
  }
}

/** Landscape tablets and medium to large screens styles*/
@media (min-width: 992px) {
  .textContainer{
    width: 25vw;
    text-align: justify;
  }
}