/** General styles*/

.background {
}

/** Phones styles*/
@media(max-width: 576px) {
  .background {
    height: 460px;
    overflow: auto;
  }
  .imagelogo{
    width: 100%;
    height: 80%;
    border-radius: 11px;
  }
  .firsttext{
    text-align: justify;
    padding-left: 13%;
    padding-right: 13%;
    font-size: 10px;
    padding-top: 10%;
    color: #858585;
  }
  .texttitlepromo{
    font-weight: bold;
    text-align: center;
    margin-left: 13%;
    font-size: 22px;
    margin-top: -8px;
  }
  .textpromo{
    border-color: red;
    border-width: 1px;
    border-style: solid;
    border-radius: 16px;
    width: 60%;
    height: 54%;
    font-size: 8px;
    text-align: center;
    color: red;
    font-weight: 700;
    padding-top: 3px;
  }
  .divpromotext{
    text-align: -webkit-right;
    margin-right: 8%;
  }
  .divpromotext a{
    text-decoration: none;
  }
  .divimg{
    display: flex;
  }
  .textimgtitle{
    position: absolute;
    margin-top: 33%;
    margin-left: 5%;
    color: white;
    font-weight: 700;
    font-size: 18px;
  }
  .textimgsubtitle{
    position: absolute;
    margin-top: 40%;
    margin-left: 5%;
    color: white;
    font-size: 13px;
  }
  .web{
    display: none;
  }
  .mobile {
    height: 100%;
    margin-top: -5vh;
  }
 .divImgScroll{
    padding-left: 25px;
    padding-right: 25px;
    margin-top: 40px;
    border-radius: 9px;
  }
  .divfull{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }
  .imgscroll{
    border-radius: 15px;
    height: 100%;
    width: 100%;
    object-fit: cover;
    cursor: pointer;
  }
}

/** Landscape phones and tablets styles*/
@media (min-width: 576px) and (max-width: 992px) {
  .background {
    margin-top: 10%;
  }
  .imagelogo{
    width: 94%;
    height: 71%;
    border-radius: 10px;
  }
  .firsttext{
    text-align: justify;
    padding-left: 13%;
    padding-right: 13%;
    font-size: 10px;
    padding-top: 10%;
    color: #858585;
  }
  .texttitlepromo{
    font-weight: bold;
    margin-left: 9%;
    font-size: 22px;
    margin-top: -8px;
  }
  .textpromo{
    border-color: red;
    border-width: 1px;
    border-style: solid;
    border-radius: 16px;
    width: 34%;
    height: 54%;
    font-size: 8px;
    text-align: center;
    color: red;
    font-weight: 700;
    padding-top: 3px;
  }
  .divpromotext{
    text-align: -webkit-right;
    margin-right: 8%;
  }
  .divpromotext a{
    text-decoration: none;
  }
  .divimgLeft{
    display: flex;
    padding-right: 0vh;
  }
  .divimgCenter{
    display: flex;
    padding-left: 0vh;
    padding-right: 0vh;
  }
  .divimgRight{
    display: flex;
    padding-left: 0vh;
  }
  .textimgtitle{
    position: absolute;
    margin-top: 36%;
    margin-left: 5%;
    color: white;
    font-weight: 700;
    font-size: 17px;
  }
  .textimgsubtitle{
    position: absolute;
    margin-top: 45%;
    margin-left: 5%;
    color: white;
    font-size: 12px;
  }
  .mobile{
    display: none;
  }
 .divImgScrollweb{
    margin-bottom: 30px;
    padding-left: 25px;
    padding-right: 25px;
    margin-top: -21px;
  }
  .divfull{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }
  .imgscrollweb{
    width: 20vw;
    margin: 20px;
    object-fit: fill;
    cursor: pointer;
  }
}

/** Landscape tablets and medium to large screens styles*/
@media (min-width: 992px) {
  .background {
    margin-top: 20vh;
  }
  .imagelogo{
    width: 94%;
    height: 71%;
    border-radius: 10px;
  }
  .firsttext{
    text-align: justify;
    padding-left: 13%;
    padding-right: 13%;
    font-size: 10px;
    padding-top: 10%;
    color: #858585;
  }
  .texttitlepromo{
    font-weight: bold;
    margin-left: 24.5vh;
    font-size: 31px;
    margin-top: -8px;
  }
  .textpromo{
    border-color: red;
    border-width: 1px;
    border-style: solid;
    border-radius: 16px;
    width: 9vw;
    height: 4vh;
    font-size: 12px;
    text-align: center;
    color: red;
    font-weight: 700;
    padding-top: 0.5vh;
  }
  .divpromotext{
    text-align: -webkit-right;
    margin-right: 13vw;
  }
  .divpromotext a{
    text-decoration: none;
  }
  .divimgLeft{
    display: flex;
    padding-right: 2vh;
    padding-left: 10vw;
  }
  .divimgCenter{
    display: flex;
    padding-left: 0vh;
    padding-right: 0vh;
  }
  .divimgRight{
    display: flex;
    padding-left: 2vh;
    padding-right: 10vw;
  }
  .textimgtitle{
    position: absolute;
    margin-top: 37%;
    margin-left: 5%;
    color: white;
    font-weight: 700;
    font-size: 17px;
  }
  .textimgtitleLeft{
    position: absolute;
    margin-top: 26%;
    margin-left: 5%;
    color: white;
    font-weight: 700;
    font-size: 17px;
  }
  .textimgsubtitleLeft{
    position: absolute;
    margin-top: 30%;
    margin-left: 5%;
    color: white;
    font-size: 12px;
  }
  .textimgsubtitle{
    position: absolute;
    margin-top: 43%;
    margin-left: 5%;
    color: white;
    font-size: 12px;
  }
  .mobile{
    display: none;
  }
  .divImgScrollweb{
    width: 23%;
    padding-left: 10vw;
    padding-right: 10vw;
    margin-top: 40px;
    border-radius: 9px;
  }
  .divfull{
    width: 100%;
    margin-bottom: 5vh;
    display: flex;
    flex-wrap: wrap;
  }
  .imgscrollweb{
    width: 25%;
    border-radius: 9px;
    margin: 25px;
    flex: 0 0 25%;
    object-fit: fill;
  }
}