/** General styles*/

.background {
  background-color: white;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  overflow-x: hidden;
}


/** Phones styles*/
@media(max-width: 576px) {
  .background {
      height: calc(100vh - 67.89px);
      z-index: 0;
      overflow: scroll;
      margin-top: 67.89px;
  }
  
  .divImgTitletop{
    padding: 0;
    margin-top: 2vh;
  }
  .divImgTitle{
    padding: 0;
  }
  .divImg{
    padding: 0;
  }
  .logoHolder {
      display: flex;
  }
  .divtopimg {
      width: 100%;
      margin: auto;
      object-fit: cover;
  }
  .divtopimg img{
    width: 100%;
    object-fit: cover;
  }
 .logoHoldertexttitle{
  position: absolute;
    color: white;
    margin-top: 151px;
    margin-left: 40px;
    font-weight: 700;
    font-size: 26px;
 }
 .logoHoldertextsub{
  position: absolute;
  color: white;
  margin-top: 182px;
  margin-left: 40px;
  font-size: 14px;
 }
 hr{
    background-color: red;
    margin-top: 0%;
    height: 3px
 }
 .logoHoldertexttitlescroll{
   font-weight: 700;
   margin-left: 40px;
   margin-bottom: 2vh  
 }
 .logoHoldertextsubscroll{
  margin-left: 40px;
  color: gray;
  font-size: 12px;
  font-weight: 500;
 }
 .divImgScroll{
   margin-bottom: 30px;
   padding-left: 25px;
   padding-right: 25px;
 }
 .imgscroll{
   width: 100%;
   border-radius: 18px;
   height: 26vh !important;
 }
 .imgbutton{
  width: 65%;
  position: static;
   
 }
 .divbutton{
  position: sticky;
  margin-top: -46px;
  padding-left: 30%;
  z-index: 99;
 }
 .web{
   display: none;
 }
 /*footer*/
 .backgroundfooter {
  height: 460px;
  background-color: #222222;
  width: 104vw;

}
.imagelogo{
  height: 3.5vh;
  margin-top: 28%;
  margin-left: 13%;
}
.firsttext{
  text-align: justify;
  padding-left: 13%;
  padding-right: 13%;
  font-size: 10px;
  padding-top: 10%;
  color: #858585;
}
.copyright{
  display: flex;
  padding-left: 13%;
  padding-right: 13%;
  font-size: 8px;
  color: #858585;
}
.colortextcopyright{
  color: red;
}
.textreservation{
  color: #ededed;
  font-size: 15px;
  font-weight: 700;
  padding-left: 13%;
  padding-right: 13%;
}
.schedule{
color: #858585;
font-size: 10px;
padding-left: 13%;
padding-right: 13%;
margin-bottom: 2vh;
}
.buttonCall{
 display: flex;
}
.imgicon img{
  width: 100%;
}
.imgCall{
  height: 100%;
  width: 100%;
  padding-top: 0%;
  
}
.desktop{
 display: none;
}
.buttonHolder{
  margin-top: 0.5vh;
}
.schedule2{
  color: #858585;
  font-size: 10px;
  padding-left: 13%;
  padding-right: 13%;
  margin-top: 0%;
}
}

/** Landscape phones and tablets styles*/
@media (min-width: 576px) and (max-width: 992px) {
  .background {
    height: 100vh;
  
    z-index: 0;
  }
  .divImgTitle{
  display: none;
  }
  .divImg{
    padding: 0;
  }
  .divtopimg{
    width: 100%;
  }
  .logoHolder {
    display: flex;
  }
  .logoHolder img {
    width: 100%;
    height: 210px;
    margin: auto;
    object-fit: cover;
  }
  .logoHoldertexttitle{
    position: absolute;
    color: white;
    margin-top: 110px;
    margin-left: 83px;
    font-weight: 700;
    font-size: 27px;
  }
  .logoHoldertextsub{
    position: absolute;
    color: white;
    margin-top: 141px;
    margin-left: 84px;
    font-size: 19px;
  }
  hr{
    background-color: red;
    margin-top: 0%;
    height: 3px
  }
  .logoHoldertexttitlescroll{
    font-weight: 700;
    margin-left: 54px;
    margin-bottom: -2px;   
  }
  .logoHoldertextsubscroll{
    margin-left: 40px;
    color: gray;
    font-size: 12px;
    font-weight: 500;
  }
  .divImgScroll{
    margin-bottom: 30px;
    padding-left: 25px;
    padding-right: 25px;
  }
  .divImgScrollweb{
    margin-bottom: 30px;
    padding-left: 25px;
    padding-right: 25px;
    margin-top: -21px;
  }
  .imgscroll{
    width: 27%;
    border-radius: 18px;
  }
  .imgbutton{
    width: 25%;
  }
  .divbutton{
    position: fixed;
    margin-top: 50px;
    z-index: 99;
  }

  .imgscrollweb{
    width: 25%;
    height: 13vh !important;
    margin-left: 41px;
    margin-top: 40px;
    border-radius: 9px;
  }
  .divfull{
    width: 100%;
  }
  .mobile{
    display: none;
  }
  .row{
    margin-top: 2vh;
  }
/*footer*/
  .backgroundfooter {
    background-color: #222222;
    height: 47vh;
    width: 102vw;
  }
  .imagelogo{
    max-width: 33%;
    margin-top: 16%;
    margin-left: 13%;
  }
  .firsttext{
    text-align: justify;
    padding-left: 13%;
    padding-right: 13%;
    font-size: 10px;
    padding-top: 10%;
    color: #858585;
  }
  .copyright{
    display: flex;
    padding-left: 13%;
    padding-right: 13%;
    font-size: 8px;
    color: #858585;
  }
  .colortextcopyright{
    color: red;
  }
  .textreservation{
    color: #ededed;
    font-size: 15px;
    font-weight: 700;
    padding-left: 13%;
    padding-right: 13%;
    margin-top: 32%;
  }
  .schedule{
    color: #858585;
    font-size: 10px;
    padding-left: 13%;
    padding-right: 13%;
    margin-top: -2%;
    margin-bottom: 2vh;
  }
  .buttonCall{
    display: flex;
    justify-content: flex-end;
  }
  .imgicon img{
    width: 100%;
  }
  .imgCall{
    height: 100%;
    width: 100%;
    padding-top: 0%;
    
  }
  .mobile{
    display: none;
  }
  .schedule2{
    color: #858585;
    font-size: 10px;
    padding-left: 13%;
    padding-right: 13%;
    margin-top: 0%;
  }
}


/** Landscape tablets and medium to large screens styles*/

@media (min-width: 992px) {
    .background {
    height: 100%;
    
    z-index: 0;
    
}
.divImgTitle{
  display: none;
}
.divImg{
  padding: 0;
}
.logoHolder {
    display: flex;
}
.logoHolder img {
    width: 100%;
    height: 100%;
    margin: auto;
    object-fit: cover;
}
.logoHoldertexttitle{
  position: absolute;
  color: white;
  margin-top: 110px;
  margin-left: 13vw;
  font-weight: 700;
  font-size: 55px;
}
.logoHoldertextsub{
  position: absolute;
  color: white;
  margin-top: 170px;
  margin-left: 13vw;
  font-size: 27px;
}
hr{
  background-color: red;
  margin-top: 0%;
  height: 3px
}
.logoHoldertexttitlescroll{
 font-weight: 700;
 margin-left: 12vw;
 margin-bottom: -8vh;  
 margin-top: 7vh; 
 font-size: 25px;
}
.logoHoldertextsubscroll{
margin-left: 40px;
color: gray;
font-size: 12px;
font-weight: 500;
}
.divImgScroll{
 margin-bottom: 30px;
 padding-left: 25px;
 padding-right: 25px;
}
.divImgScrollweb{
  width: 23%;
  padding-left: 10vw;
  padding-right: 10vw;
  margin-top: 40px;
  border-radius: 9px;
}
.imgscroll{
 width: 27%;
 border-radius: 18px;
}
.imgbutton{
 width: 25%;
 
}
.divbutton{
position: fixed;
margin-top: 50px;
z-index: 99;
}

.imgscrollweb{
  width: 29%;
  height: 29vh !important;
  margin-left: 41px;
  cursor: pointer;
  border-radius: 9px;
  margin-bottom: 4vh;
  object-fit: cover;
}
.divfull{
  width: 100%;
  margin-bottom: 5vh;
}
.mobile{
  display: none;
}
.divtopimg{
  width: 100%;
  height: 35vh;
}
.web{
  margin-top: 8vh;
 
}
/*footer*/
.backgroundfooter {
height: 49vh;
background-color: #222222;
width: 99.9vw;
}
.imagelogo{
  max-width: 26%;
  margin-top: 16%;
  margin-left: 13%;
}
.firsttext{
  text-align: justify;
  padding-left: 13%;
  padding-right: 13%;
  font-size: 10px;
  padding-top: 5%;
  color: #858585;
}
.copyright{
  display: flex;
  padding-left: 13%;
  padding-right: 13%;
  font-size: 8px;
  color: #858585;
}
.colortextcopyright{
  color: red;
}
.textreservation{
  color: #ededed;
  font-size: 15px;
  font-weight: 700;
  padding-left: 27%;
  padding-right: 13%;
  margin-top: 24%;
}
.schedule{
color: #858585;
font-size: 10px;
padding-left: 27%;
padding-right: 13%;
margin-top: 0%;
margin-bottom: 2vh;
}
.schedule2{
  color: #858585;
  font-size: 10px;
  padding-left: 27%;
  padding-right: 13%;
  margin-top: 0%;
}
.buttonCall{
 display: flex;
 justify-content: flex-end;
}
.imgicon img{
  width: 100%;
}
.imgCall{
  height: 100%;
  width: 100%;
  padding-top: 0%;
  
}

}