/** General styles*/

.background {
    background-image: url("../../assets/home/CAMARONPLANCHA_MENU-normal.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    object-fit: cover;
}


/** Phones styles*/

@media(max-width: 576px) {
    .background {
        height: 100vh;
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
        object-fit: cover;
        
    }
    .layout{
        background-color: rgba(0, 0, 0, 0.7);
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    .logoHolder img {
        max-width: 60%;
        margin-left: 17vw;
        /* margin: auto;*/
        margin-top: 15vh;
    }
    .textHolder {
        display: flex;
        height: 5vh;
    }
    .textHolder p {
        color: white;
        font-weight: 400;
        margin: auto;
        width: 49vw;
        text-align: center;
        font-size: 18px;
        font-family: "regular";
    }
    .buttonHolder {
        
    }
    .buttonHolder img {
        max-width: 85%;
        /* margin: auto; */
        margin-left: -22vw;
        margin-top: 0;
    }
    .textButton{
        margin: auto;
        color: white;
    }
    .imgicon img{
        
        width: 100%;
    }
}



/** Landscape phones and tablets styles*/

@media (min-width: 576px) and (max-width: 992px) {
    .background {
        height: 100vh;
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
      
 
	
  /* Preserve aspet ratio */
  min-width: 100%;
  min-height: 100%;
    }

    .background {
        background-image: url("../../assets/home/CAMARONPLANCHA_MENU-normal.png");
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }
    .layout{
        background-color: rgba(0, 0, 0, 0.7);
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    .logoHolder {
        display: flex;
    }
    .logoHolder img {
        max-width: 32%;
        margin: auto;
        margin-top: 25vh;
        
    }
    .textHolder {
        display: flex;
        margin-top: 2%;
        margin-bottom: 5%;
    }
    .textHolder p {
      color: white;
      font-weight: 400;
      margin: auto;
      width: 33vw;
      text-align: center;
      font-size: 19px;
      font-family: "regular";
    }
    .buttonHolder {
        
    }
    .buttonHolder img {
        max-width: 45%;
        /* margin: auto; */
        margin-left: -12vw;
        margin-top: -7vh;
    }
    .imgicon img{
        width: 100%;
    }
}


/** Landscape tablets and medium to large screens styles*/

@media (min-width: 992px) {
    .background {
        height: 100vh;
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
    }
    .background {
        background-image: url("../../assets/home/CAMARONPLANCHA_MENU-normal.png");
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        object-fit: cover;
    }
    .layout{
        background-color: rgba(0, 0, 0, 0.7);
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    .logoHolder {
        display: flex;
    }
    .logoHolder img {
        max-width: 26%;
        margin: auto;
        margin-top: 30vh;
    }
    .textHolder {
        display: flex;
        margin-top: 2%;
        margin-bottom: 2%;
        height: 5vh;
    }
    .textHolder p {
      color: white;
      font-weight: 400;
      margin: auto;
      width: 35vw;
      text-align: center;
      font-size: 25px;
    }

    .buttonHolder img {
      max-width: 28%;
      margin: auto;
    }
    .imgicon img {
        width: 100%;
    }
}