body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
@font-face {
  font-family: 'Avenir';
  src: url('./assets/fonts/Avenir Next.ttc');
}
@font-face {
  font-family: 'arial-bold';
  src: url('./assets/fonts/arial-bold-italic.ttf');
}
@font-face {
  font-family: 'avenir-italic';
  src: url('./assets/fonts/Metropolis-MediumItalic.otf');
}
@font-face {
  font-family: 'avenir-normal';
  src: url('./assets/fonts/Metropolis-Medium.otf');
}

