/** General styles*/


/** Phones styles */

@media(max-width: 576px) {
    .navToggler span {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='10' height='10' viewBox='15 8 2 25'%3e%3cpath stroke='white' stroke-linecap='round' stroke-miterlimit='20' stroke-width='1.8' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e") !important;
      width: 14vw;
      height: 3vh;
    }	
    .navToggler{
      margin-top: -0.5vh;
    }
    .navToggler{
      border-color: transparent !important;
    }
		.navTogglerRed span{
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='10' height='10' viewBox='15 8 2 25'%3e%3cpath stroke='white' stroke-linecap='round' stroke-miterlimit='20' stroke-width='1.8' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e") !important;
    }
    .navTogglerRed{
      margin-left: 60vw;
      position: fixed;
      border-color: transparent !important;
    }
    .navbarHead {
      width: 100vw;
      margin-left: -15px;
    }
    .navbarNav {
      background-image: url("../../assets/home/bottom.png");
      background-position: top;
      background-size: cover;
      width: 100vw;
      margin-left: -15px;
      margin-right: -15px;
    }
    .ul, .ul2 {
      height: calc(100vh - 47.89px);
      margin-top: calc(20vh - 47.89px);
    }
    .ul li, .ul2 li {
      margin-top: 1vh;
      margin-bottom: 1vh;
      min-height: 10vh;
    }
    .ul li div, .ul2 li div {
      height: 100%;
      display: flex;
    }
    .ul a, .ul2 a {
      display: flex;
      width: 100%;
      height: 100%;
      text-decoration: none;
    }
    .ul p, .ul2 p {
			color: white !important;
      font-weight: 700 !important;
      font-size: 24px;
      margin: auto auto auto 0;
		} 
		.ul img, .ul2 img {
      max-height: 10vh;
      max-width: 77px;
      margin: auto;
		}
    .navToggler{
      right: 5vw;
      position: fixed;
    }
    .logo a{
      width: 40%;
      margin-top: -1vh;
    }
    .logo img{
      width: 58%;
    }
}


/** Landscape phones and tablets styles*/

@media (min-width: 576px) and (max-width: 992px) {
  .navToggler span {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='10' height='10' viewBox='15 8 2 25'%3e%3cpath stroke='white' stroke-linecap='round' stroke-miterlimit='20' stroke-width='1.8' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e") !important;
  }	
  .ul {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
  .ul a{
    color: white !important;
    font-weight: 700 !important;
    font-size: 10px !important;
    text-decoration: none;
  } 
  .ul img{
    width: 95%;
    padding-top: 42%;
  }
  .ul .itemnav{
    margin-left: 3%;
    display: none;
  }
  .navTogglerRed{
    margin-left: 60vw;
  }
  .ul2 {
    background-color: white;
    background-repeat: no-repeat;
    background-position: top;
    background-size: cover;
    width: 100vw;
    position: fixed;
    left: 0;
    margin-top: 0px !important;
    justify-content: flex-end;
    justify-content: flex-end;
  }
  .ul2 a{
    color: #999999 !important;
    font-weight: 600 !important;
    text-decoration: none;
  } 
  .ul2 img{
    width: 71%;
    padding-top: 42%;
  }
  .ul2 .itemnav{
    margin-left: 3%;
    display: none;
  }
  .navToggler{
    padding-left: 79%;
    padding-right: 0;
  }
  .logo a{
    width: 28%;
    position: fixed;
    z-index: 99;
    margin-top: -1.5vh;
  }
  .logo img{
    width: 63%;
  }
  .hidden{
    width: 39vw;
    padding-right: 1%;
  }
  .ul li {
    margin: auto;
  }
  .ul2 li{
    font-size: 12px;
    margin-left: 0vh;
    margin-top: 5vh;
    margin-bottom: 2vh;
    margin-right: 5.8vw;
  }
}


/** Landscape tablets and medium to large screens styles*/

@media (min-width: 992px) {
  .navToggler span {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='10' height='10' viewBox='15 8 2 25'%3e%3cpath stroke='white' stroke-linecap='round' stroke-miterlimit='20' stroke-width='1.8' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e") !important;
  }	
  .ul {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-left: 15vh;
  }
  .ul a{
    color: white !important;
    font-weight: 700 !important;
    font-size: 13px !important;
    margin-left: 2vw;
    text-decoration: none;
  } 
  .ul img{
    width: 95%;
    padding-top: 42%;
  }
  .ul .itemnav{
    margin-left: 3%;
    display: none;
  }
  .navTogglerRed{
    margin-left: 60vw;
  }
  .ul2 {
    background-color: white;
    background-repeat: no-repeat;
    background-position: top;
    background-size: cover;
    width: 100vw;
    position: fixed;
    left: 0;
    margin-top: -5vh !important;
    justify-content: flex-end;
  }
  .ul2 a{
    color: #999999 !important;
    font-weight: 600 !important;
  } 
  .ul2 img{
    width: 71%;
    padding-top: 42%;
  }
  .ul2 .itemnav{
    margin-left: 3%;
    display: none;
  }
  .navToggler{
    padding-left: 79%;
    padding-right: 0;
  }
  .logorojo{
    width: 10%;
    position: fixed;
    z-index: 99;
  }
  .logoblanco{
    width: 50%;
  }
  .hidden{
    width: 39vw;
    padding-right: 1%;
  }
  .ul li {
    margin: auto;
  }
  .ul2 li{
    font-size: 15px;
    margin-left: 3vh;
    margin-top: 10vh;
    margin-bottom: 2vh;
    margin-right: 4.8vw;
  }
}