/** General styles*/


/** Phones styles*/
@media(max-width: 576px) {
  .titulo {
    margin: 5vh 0;
    text-align: center;
    font-weight: bold;
    z-index: 1;
  }
  .starMenuContainerLeft, .starMenuContainerRight{
    width: 100%;
    
  }
  .divcontainer{
    background-color: white;
    border-radius: 15%;
    margin-bottom: 4vh;
    z-index: 1;
  }
  .starMenuContainerLeft {
    margin-top: 5vh;
  }
  .starMenuContainerRight img{
    border-radius: 40px;
  }
  .starMenuContainerLeft img{
    border-radius: 40px;
  }
  .starMenuContainerRight {
   
  }
  .starMenuContainerLeft img, 
  .starMenuContainerRight img {
    max-width: 90px;
    position: absolute;
    bottom: 0;
  }
  .containerTitle {
    font-size: 18px;
    font-weight: 800;
    margin: 0;
  }
  .containerButton {
    text-decoration: none;
  }
  .containerText{
    text-align: justify;
    margin-top: 2vh;
  }
  .containerButton p {
    color: red;
    border-color: red;
    border-radius: 15px;
    border-width: 1px;
    border-style: solid;
    padding: 4px;
    font-size: 8px;
    font-weight: 700;
    text-align: center;
  }
  .floatBG {
    position: absolute;
    width: 100%;
    height: 50%;
    overflow: hidden;
    z-index: -1;
  }
  .left {
    width: 100%;
    height: 30vh;
    background-image: url("../../assets/promociones/left.png");
    background-repeat: no-repeat;
    background-position: left;
    background-size: contain;
  }
}

/** Landscape phones and tablets styles*/
@media (min-width: 576px) and (max-width: 992px) { 
  .titulo {
    margin: 5vh 0;
    text-align: center;
    font-weight: bold;
    font-size: 20px;
    z-index: 2;
  }
  .divcontainer {
    background-color: white;
    border-radius: 40px;
    display: flex;
    z-index: 1;
  }
  .starMenuContainerLeft, .starMenuContainerRight{
    margin: auto;
  }
  .starMenuContainerLeft {
    margin-left: auto;
  }
  .starMenuContainerRight img{
    border-radius: 40px;
  }
  .starMenuContainerLeft img{
    border-radius: 40px;
  }
  .starMenuContainerRight {
    margin-right: auto;
  }
  .starMenuContainerLeft img, 
  .starMenuContainerRight img {
    max-width: 90px;
    position: absolute;
    bottom: 0;
  }
  .containerTitle {
    font-size: 18px;
    font-weight: 800;
    margin: 0;
  }
  .containerButton {
    text-decoration: none;
  }
  .containerButton p {
    color: red;
    border-color: red;
    border-radius: 15px;
    border-width: 1px;
    border-style: solid;
    padding: 4px;
    font-size: 8px;
    font-weight: 700;
    text-align: center;
    
  }
  .floatBG {
    position: absolute;
    width: 100%;
    height: 50%;
    overflow: hidden;
  }
  .left {
    width: 100%;
    height: 20vh;
    background-image: url("../../assets/promociones/left.png");
    background-repeat: no-repeat;
    background-position: left;
    background-size: contain;
  }
  .right {
    width: 100%;
    height: 20vh;
    background-image: url("../../assets/promociones/right.png");
    background-repeat: no-repeat;
    background-position: right;
    background-size: contain;
  }
}

/** Landscape tablets and medium to large screens styles*/
@media (min-width: 992px) { 
  .titulo {
    margin: 5vh 0;
    text-align: center;
    font-size: 24px;
    font-weight: 800;
  }
  .divcontainer {
    background-color: white;
    border-radius: 40px;
    display: flex;
    z-index: 1;
  }
  .starMenuContainerRight img{
    border-radius: 40px;
  }
  .starMenuContainerLeft img{
    border-radius: 40px;
  }
  .starMenuContainerLeft, .starMenuContainerRight{
    margin: auto;
  }
  .starMenuContainerLeft {
    margin-left: auto;
  }
  .starMenuContainerRight {
    margin-right: auto;
  }
  .starMenuContainerLeft img, 
  .starMenuContainerRight img {
    max-width: 135px;
    position: absolute;
    bottom: 0;
  }
  .containerTitle {
    font-size: 18px;
    font-weight: 800;
    margin: 0;
  }
  .containerButton a {
    text-decoration: none;
  }
  .starMenuContainerLeft a{
    text-decoration: none;
  }
  .starMenuContainerRight a{
    text-decoration: none;
  }
  .containerButton p {
    color: red;
    border-color: red;
    border-radius: 15px;
    border-width: 1px;
    border-style: solid;
    padding: 4px;
    font-size: 10px;
    font-weight: 700;
    text-align: center;
    width: 75px;
  }
  .floatBG {
    position: absolute;
    width: 100%;
    height: 50%;
    overflow: hidden;
  }
  .left {
    width: 100%;
    height: 25vh;
    background-image: url("../../assets/promociones/left.png");
    background-repeat: no-repeat;
    background-position: left;
    background-size: contain;
  }
  .right {
    width: 100%;
    height: 25vh;
    background-image: url("../../assets/promociones/right.png");
    background-repeat: no-repeat;
    background-position: right;
    background-size: contain;
  }
}