/** General styles*/
.AbsoluteRow {
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 200;
  padding: 10px 0;
}

/** Phones styles */
@media(max-width: 576px) {
  .AbsoluteRow {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 100;
    background-color: rgba(0, 0, 0, 1);
    height: 67.89px;
  }
}

/** Landscape phones and tablets styles*/
@media (min-width: 576px) and (max-width: 992px) {

}

/** Landscape tablets and medium to large screens styles*/
@media (min-width: 992px) {

}