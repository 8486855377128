/** General styles*/

.background {
}


/** Phones styles*/

@media(max-width: 576px) {
 .background {
      
    height: 36vh;
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px; 
     
  }
 
   /*
  .itemimgArrow{
    width: 50px;
  }
  
  
  
  ul {
    margin-top: -30px !important;
    
    display: flex;
    
    position: relative;
    
  }
  .Carousel{
    z-index: 0;
  }*/
  .itemimgCarouselleft{
    width: 30px;
    position: absolute;
    margin-top: 10vh;
    margin-left: 64%;
    z-index: 99;
  }
  .itemimgCarouselright{
    width: 30px;
    position: absolute;
    margin-top: 10vh;
    margin-left: -21%;
  }
  .itemimg{
    width: 100%;
    border-radius: 12px;
  }
  .carContainer{
  margin-top: 33vh;
  margin-left: -80vw;
    
  }
  .divtestimonios{
    background-color: white;
    filter: alpha();
  }
  .textTitle{
    position: absolute;
    margin-top: -28vh;
    margin-left: 18px;
    color: white;
  }
  .textname{
    position: absolute;
    margin-top: -25vh;
    margin-left: 18px;
    color: white;
    font-weight: 700;
    line-height: 2vh;
    font-size: 13px
  }
  .textitem{
    position: absolute;
    margin-top: -23vh;
    font-size: 9px;
    margin-left: 18px;
    width: 73%;
    color: white;
  }
  .textnamesecond{
    position: absolute;
    margin-top: -16vh;
    margin-left: 18px;
    color: white;
    font-weight: 700;
  }
  .textitembottom{
    position: absolute;
    margin-top: -13vh;
    font-size: 9px;
    margin-left: 18px;
    width: 73%;
    color: white;
  }
  .divcarousemobile{
    padding-left: 0;
    padding-right: 0;
    background-image: url('../../assets/MenuIMG/Entradas/ENTRADAS/TACP_MENU-normal.png');
    background-repeat: no-repeat;
    background-size: 100%;
  }
  .dots{
    margin-top: -9% !important;
    margin-left: -64%;
  }
  .divcarousemobile ul li .BrainhubCarousel__dots .BrainhubCarousel__dot:before::before{
    background: white !important;
  }
  .divcarouseweb{
    display: none;
  }
}



/** Landscape phones and tablets styles*/

@media (min-width: 576px) and (max-width: 992px) {
  .background {
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px; 
    margin-top: 50px; 
}

 /*
.itemimgArrow{
  width: 50px;
}



ul {
  margin-top: -30px !important;
  
  display: flex;
  
  position: relative;
  
}
.Carousel{
  z-index: 0;
}*/
.itemimgCarouselleft{
  width: 30px;
  position: absolute;
  margin-top: 21%;
  margin-left: 64%;
  z-index: 99;
  cursor: pointer !important;
}
.itemimgCarouselright{
  width: 30px;
  position: absolute;
  margin-top: 21%;
  margin-left: -21%;
  cursor: pointer !important;
  z-index: 99;
}
.itemimg{
  width: 100%;
  border-radius: 12px;
}
.carContainer{
margin-top: 33vh;
margin-left: -98vw;
height: 25vh;
  
}
.divtestimonios{
  background-color: white;
  filter: alpha();
}
.textTitle{
  position: absolute;
    margin-top: -29vh;
    margin-left: 10vw;
    color: white;
    font-weight: 800;
    font-size: 19px;
}
.textname{
  position: absolute;
  margin-top: -22vh;
  margin-left: 10vw;
  color: white;
  font-size: 18px;
}
.textitem{
  position: absolute;
  margin-top: -18vh;
  font-size: 13px;
  margin-left: 10vw;
  width: 35%;
  color: white;
}
.textnameright{
  position: absolute;
    margin-top: -22vh;
    margin-left: 51vw;
    color: white;
    font-size: 18px;

}
.textitemright{
  position: absolute;
  margin-top: 0vh;
  margin-left: 54vw;
  width: 35%;
  color: white;
  font-size: 13px;

}
.textnamesecond{
  position: absolute;
  margin-top: -4vh;
  margin-left: 10vw;
  color: white;
  font-size: 18px;

}
.textitembottom{
  position: absolute;
  margin-top: 0vh;
  font-size: 13px;
  margin-left: 10vw;
  width: 35%;
  color: white;
}
.textnamesecondright{
  position: absolute;
  margin-top: -4vh;
  margin-left: 51vw;
  color: white;
  font-size: 18px;

}
.textitembottomright{
  position: absolute;
  margin-top: -18vh;
  font-size: 13px;
  margin-left: 51vw;
  width: 35%;
  color: white;
}
.textitembottomrightsecond{
  position: absolute;
  margin-top: 0vh;
  font-size: 13px;
  margin-left: 51vw;
  width: 35%;
  color: white;
}
.divcarouseweb{
  padding-left: 0;
  padding-right: 0;
  background-image: url('../../assets/MenuIMG/Entradas/ENTRADAS/TACP_MENU-normal.png');
  background-repeat: no-repeat;
  background-size: 100%;
  height: 59vh;
}
.dots{
  margin-top: -13% !important;
  margin-left: -64%;
}
.divcarouseweb ul li .BrainhubCarousel__dots .BrainhubCarousel__dot:before::before{
  background: white !important;
}
.divcarousemobile{
  display: none;
   
 }
}


/** Landscape tablets and medium to large screens styles*/

@media (min-width: 992px) {
  .background {
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px; 
    margin-top: 50px; 
}

 /*
.itemimgArrow{
  width: 50px;
}



ul {
  margin-top: -30px !important;
  
  display: flex;
  
  position: relative;
  
}
.Carousel{
  z-index: 0;
}*/
.itemimgCarouselleft{
  width: 30px;
  position: absolute;
  margin-top: 17%;
  margin-left: 64%;
  z-index: 99;
  cursor: pointer !important;
}
.itemimgCarouselright{
  width: 30px;
  position: absolute;
  margin-top: 17%;
  margin-left: -21%;
  cursor: pointer !important;
  z-index: 99;
}
.itemimg{
  width: 100%;
  border-radius: 12px;
}
.carContainer{
margin-top: 33vh;
margin-left: -98vw;
height: 15vh;
}
.divtestimonios{
  background-color: white;
  filter: alpha();
}
.textTitle{
  position: absolute;
    margin-top: -27vh;
    margin-left: 10vw;
    color: white;
    font-weight: 800;
    font-size: 29px;
}
.textname{
  position: absolute;
  margin-top: -19vh;
  margin-left: 10vw;
  color: white;
  font-size: 22px;
}
.textitem{
  position: absolute;
  margin-top: -15vh;
  font-size: 14px;
  margin-left: 10vw;
  width: 32%;
  color: white;
}
.textnameright{
  position: absolute;
  margin-top: -19vh;
  margin-left: 54vw;
  color: white;
  font-size: 22px;

}
.textitemright{
  position: absolute;
  margin-top: 0vh;
  margin-left: 54vw;
  width: 31%;
  color: white;
  font-size: 14px;

}
.textnamesecond{
  position: absolute;
  margin-top: 0vh;
  margin-left: 10vw;
  color: white;
  font-size: 22px;

}
.textitembottom{
  position: absolute;
  margin-top: 4vh;
  font-size: 14px;
  margin-left: 10vw;
  width: 31%;
  color: white;
}
.textnamesecondright{
  position: absolute;
  margin-top: 0vh;
  margin-left: 54vw;
  color: white;
  font-size: 22px;

}
.textitembottomright{
  position: absolute;
  margin-top: -15vh;
  font-size: 14px;
  margin-left: 54vw;
  width: 31%;
  color: white;
}
.textitembottomrightsecond{
  position: absolute;
  margin-top: 4vh;
  font-size: 14px;
  margin-left: 54vw;
  width: 31%;
  color: white;
}

.divcarouseweb{
  padding-left: 0;
  padding-right: 0;
  background-image: url('../../assets/MenuIMG/Entradas/ENTRADAS/TACP_MENU-normal.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 60vh;
  margin-top: 10vh;
}
.dots{
  margin-top: -3% !important;
  margin-left: -64%;
}
.divcarouseweb ul li .BrainhubCarousel__dots .BrainhubCarousel__dot:before::before{
  background: white !important;
}
.divcarousemobile{
  display: none;
   
 }
}